import { render, staticRenderFns } from "./OptOut.vue?vue&type=template&id=79d4fb15&scoped=true&class=container-fluid%20h-100&"
import script from "./OptOut.vue?vue&type=script&lang=js&"
export * from "./OptOut.vue?vue&type=script&lang=js&"
import style0 from "./OptOut.vue?vue&type=style&index=0&id=79d4fb15&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d4fb15",
  null
  
)

export default component.exports