<template class="container-fluid h-100">
  <div class="row m-0 p-0">
    <div class="col-3 m-0 p-0" v-if="isDesktop">
      <sidebar :title="leftTitle" :smallTitle="leftSmallTitle"></sidebar>
    </div>
    <div class="col-9 p-0 h-100 d-flex flex-column">
      <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0 px-3 w-75 right-scrollable w-75 bg_darkest" :class="{'w-100': !isDesktop}">
          <div class="row pt-5">
            <div class="col-12" v-if="completed">
              <div class="row">
                <div class="col-12">
                  Your request has been successfully logged and your data will soon be removed from our DB.
                </div>
                <div class="col-12 pt-3">
                  We remain committed to our purpose of designing travel experiences and journeys that enrich your life, and hope to have the opportunity of serving you.
                </div>
                <div class="col-12 pt-3">
                  With kind regards,
                </div>
                <div class="col-12 pt-1">
                  Charmaine Banks
                </div>
                <div class="col-12 pt-1">
                  General Manager, MORE Family Collection Concierge
                </div>
              </div>
            </div>
            <div class="col-12" v-else-if="guest && !processing">
              <div class="row">
                <div class="col-12">
                  Dear {{name}},
                </div>
                <div class="col-12 pt-3">
                  We respect your wish to opt out from receiving all email communications from MORE Family Collection Concierge.
                </div>
                <div class="col-12 pt-3">
                  We'd like to remind you that this includes quotes, confirmations and general communications relating to bookings and enquiries.
                </div>
              </div>
              <div class="row pt-5">
                <div class="col-12 pt-5 text-center">
                  Please click on the button below if you want to proceed or simply close this page if you want your information be kept in our secure database.
                </div>
                <div class="col-12 py-5 text-center">
                  <span @click.stop="optOut">
                    <optOutButton class="opt-out-button clickable" />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12" v-else>
              <div class="row">
                <div class="col-12">
                  {{message}}
                </div>
              </div>
            </div>
          </div>
          <div class="row m-0 p-0 bg_darkest pt-5" v-if="!isDesktop">
            <div class="col m-0 p-3 text-right">
              <img src="../assets/images/more_logo.png" style="max-width: 40vw;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OptOut',
  components: {
    optOutButton: () => import('@/assets/buttons/OptOut'),
    sidebar: () => import('@/components/SideBar202012.vue')
  },
  data () {
    return {
      code: null,
      guest: null,
      leftSmallTitle: null,
      leftTitle: 'My personal Information',
      message: 'Checking code...',
      processing: true,
      completed: false
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'isTablet'
    ]),
    name () {
      return this.guest ? (this.guest.name ? this.guest.name : this.guest.name) : ''
    },
    isDesktop () {
      return !this.isMobile && !this.isTablet
    }
  },
  async created () {
    this.code = this.$route.params.code
    const errMessage = 'Impossible to find guest. Please verify the code [' + this.code + ']'
    try {
      const res = await this.$api.guest.verifyOptOutCode(this.code)
      if (res.status) {
        const data = res.data
        this.completed = data.alreadyDone || false
        this.guest = data.guest
      }
    } catch (error) {
    }
    if (!this.guest) {
      this.message = errMessage
    }
    this.processing = false
  },
  methods: {
    async optOut () {
      this.message = 'Processing request...'
      this.processing = true
      try {
        const res = await this.$api.guest.optOut(this.code)
        if (res.status === 200) {
          this.completed = true
        }
      } catch (error) {
      }
      if (!this.completed) {
        this.message = 'Something went wrong. Please contact your travel planner for assistance.'
      }
      this.processing = false
    }
  }
}
</script>
<style scoped>
.right-scrollable {
  position: fixed;
  height: 100%;
  overflow: auto;
}
.opt-out-button {
 width: 200px;
 border: 1px solid var(--sand);
}
</style>
